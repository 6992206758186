import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useApi } from '~/api'
import * as st from '~/assets/styl/Stores.module.styl'

const Stores = () => {
  const storesDatas = useApi(
    useStaticQuery(graphql`
      query {
        allNumbers {
          nodes {
            number
            text
            icon
          }
        }
      }
    `).allNumbers.nodes,
    'numbers'
  )

  return (
    <div className={st.stores}>
      <div>
        {storesDatas.map((item, index) => (
          <div key={index}>
            <img src={item.icon} alt="" />
            <span>{item.number}</span>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Stores
