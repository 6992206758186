import React from 'react'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps } from 'gatsby'
import { Nav } from '~/components'

const Layout = ({ children, location }: PageProps) => (
  <>
    {location.pathname === '/' && <Nav location={location} />}
    {location.pathname === '/politica-de-privacidade' && (
      <Nav location={location} />
    )}
    {location.pathname === '/termos-de-uso' && <Nav location={location} />}
    <TransitionProvider
      location={location}
      mode="immediate"
      enter={{
        opacity: 0,
      }}
      usual={{
        opacity: 1,
      }}
      leave={{
        opacity: 0,
        config: {
          duration: 100,
        },
      }}
    >
      <TransitionViews>{children}</TransitionViews>
    </TransitionProvider>
  </>
)

export default Layout
