import { useState, useEffect } from 'react'
import api from '.'

const useApi = (defaultValue, path) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    api('get', path)
      .then((data) => setValue(data.result))
      .catch((err) => {
        console.error(err)
        setValue(null)
      })
  }, [])

  return value
}

export default useApi
