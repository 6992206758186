import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useApi } from '~/api'
import Slider from 'react-slick'
import * as st from '~/assets/styl/Sales.module.styl'
import cn from 'classnames'

type Offer = {
  name: string
  url: string
  external: boolean
  image: string
  mobile: string
  miniature: string
}

const Sales = () => {
  const offersData: Array<Offer> = useApi(
    useStaticQuery(graphql`
      query {
        allOffers {
          nodes {
            id
            name
            external
            image
            mobile
            miniature
          }
        }
      }
    `).allOffers.nodes,
    'offers'
  )

  const [url, setUrl] = useState(offersData[0].url || null)
  const [image, setImage] = useState(offersData[0].image || null)
  const [name, setName] = useState(offersData[0].name || null)
  const [selected, setSelected] = useState(false)

  const getInfos = (url, image, name, index) => {
    setUrl(url)
    setImage(image)
    setName(name)
    setImage(offersData[index].image)
    setName(offersData[index].name)
    setUrl(offersData[index].url)
  }

  useEffect(() => {
    let current = offersData.findIndex((i) => i.image === image)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowConst = window as any
    windowConst.currentImage = current // shared variable
    setTimeout(() => {
      if (current !== windowConst.currentImage) return
      current = (current + 1) % offersData.length
      setImage(offersData[current].image)
      setName(offersData[current].name)
      setUrl(offersData[current].url)
    }, 4000)
  }, [image, offersData])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: 'linear',
  }

  return (
    <div className={st.sales}>
      <section className={cn(st.content1364)}>
        <h2>Ofertas</h2>
        <ul className={st.deskList}>
          {offersData.map((item, index) => (
            <li key={index} className={image === item.image && st.active}>
              <img
                src={item.miniature}
                alt={item.name}
                onClick={() => getInfos(item.url, item.image, item.name, index)}
              />
            </li>
          ))}
        </ul>
        <img src={image} alt={name} className={st.active} />
      </section>
      <Slider {...settings} className="slick-offers-mobile">
        {offersData.map((item, index) => (
          <div className={st.cardWrapper} key={index}>
            <div className={st.cardImage}>
              <img src={item.mobile} alt={item.name} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Sales
