import React, { useState, useRef } from 'react'
import { navigate } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'
import InputMask from 'react-input-mask'
import api, { useApi } from '~/api'
import * as st from '~/assets/styl/Form.module.styl'

type Error = {
  subject?: string
}

const Form = ({ id }: { id?: number }) => {
  const [nome, setNome] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [assunto, setAssunto] = React.useState('')
  const [mensagem, setMessage] = React.useState('')
  const [termos, setTermos] = React.useState(false)
  const [errors, setErrors] = useState<Error>({})

  const [btnForm, setBtnForm] = React.useState('Enviar')

  const subjectsData = useApi(
    useStaticQuery(graphql`
      query {
        allSubjects {
          nodes {
            value
            name
          }
        }
      }
    `).allSubjects.nodes,
    'subjects'
  )

  const recaptchaRef = useRef<ReCAPTCHA>()

  const handleSubmit = (e) => {
    e.preventDefault()
    setBtnForm('Carregando...')
    recaptchaRef.current.executeAsync().then((recaptcha) => {
      api(
        'post',
        'leads/contact',
        JSON.stringify({
          station: id,
          name: nome,
          email,
          phone,
          privacy: termos,
          subject: assunto,
          message: mensagem,
          recaptcha,
        })
      )
        .then(() => navigate('/sucesso/'))
        .catch((e) => {
          if (e.errors) setErrors(e.errors)
          else console.error(e)
          setBtnForm('Enviar')
        })
      recaptchaRef.current?.reset()
    })
  }

  return (
    <form className={st.core} onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          id="nome"
          name="nome"
          placeholder="Seu nome"
          required
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Seu e-mail"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <InputMask
          type="text"
          id="telefone"
          name="telefone"
          placeholder="Telefone"
          mask="(99) 99999-9999"
          required
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <select
          name="select"
          value={assunto}
          onChange={(e) => setAssunto(e.target.value)}
        >
          <option value="" disabled hidden selected>
            Assunto
          </option>
          {subjectsData.map((item, index) => (
            <option key={index} value={item.id || item.value}>
              {item.name}
            </option>
          ))}
        </select>
        {errors.subject}
      </div>
      <textarea
        id="mensagem"
        name="mensagem"
        placeholder="Mensagem"
        value={mensagem}
        onChange={(e) => setMessage(e.target.value)}
        required
      ></textarea>
      <input
        type="checkbox"
        value="termos"
        name="termos"
        id="termos"
        required
        checked={termos}
        onChange={({ target }) => setTermos(target.checked)}
      />
      <label htmlFor="termos">
        Declaro que li e aceito a{' '}
        <a href="/politica-de-privacidade">Política de privacidade</a>
      </label>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LcIUC8gAAAAADQxE_SyenZ6nIhwjKx6hK1Y5F5J"
        size="invisible"
      />
      <div>
        <button className={btnForm === 'Enviar' && st.seta}>{btnForm}</button>
      </div>
    </form>
  )
}

export default Form
