import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useApi } from '~/api'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as st from '~/assets/styl/Units.module.styl'
import cn from 'classnames'

const Units = () => {
  const unitsCards = useApi(
    useStaticQuery(graphql`
      query {
        allStations {
          nodes {
            id
            image
            slug
            title
            address
            hours
            latitude
            restaurant
            longitude
            open24_hours
            phone
            whatsapp
            name
          }
        }
      }
    `).allStations.nodes,
    'stations?max=9999'
  )

  const [unitsOpen, setUnitsOpen] = useState(false)

  const slider = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  function handleSlideChange(index) {
    setCurrentSlide(index)
  }

  const belowTwo = unitsCards.filter((card, index) => card && index < 2)
  const overTwo = unitsCards.filter((card, index) => card && index >= 2)

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
  }

  return (
    <section className={st.content1364} id="unidades">
      <h2>Nossas unidades</h2>
      <Slider {...settings} ref={slider} className="slick-units">
        {unitsCards.map((card, index) => (
          <Link to={card.slug} key={index}>
            <div className={st.sliderCard}>
              <img src={card.image} alt={card.title} />
              <div className={st.flags}>
                {card.open24_hours === true && <span>Aberto 24h</span>}
                {card.restaurant === true && (
                  <span className={st.restaurant}>Restaurante</span>
                )}
              </div>
              <div className={st.sliderText}>
                <div>
                  <h3>{card.name}</h3>
                  <address>{card.address}</address>
                  <p>
                    Telefone: <span>{card.phone}</span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </Slider>
      <div className={cn(st.controls, 'slick-units')}>
        <button
          onClick={() => {
            slider?.current?.slickPrev()
            handleSlideChange(currentSlide - 1)
          }}
        ></button>
        <button
          onClick={() => {
            slider?.current?.slickNext()
            handleSlideChange(currentSlide + 1)
          }}
        ></button>
      </div>
      {belowTwo.map((item, index) => (
        <Link to={item.slug} key={index}>
          <div className={cn(st.sliderCard, st.mobileCard)}>
            <img src={item.image} alt={item.title} />
            <div className={st.flags}>
              {item.open24_hours === true && <span>Aberto 24h</span>}
              {item.restaurant === true && (
                <span className={st.restaurant}>Restaurante</span>
              )}
            </div>
            <h3>{item.name}</h3>
            <p>{item.address}</p>
            <p>
              Telefone: <span>{item.phone}</span>
            </p>
          </div>
        </Link>
      ))}
      {!unitsOpen && (
        <button className={st.btnMobile} onClick={() => setUnitsOpen(true)}>
          Carregar mais
        </button>
      )}
      {unitsOpen &&
        overTwo.map((item, index) => (
          <Link to={item.slug} key={index}>
            <div className={cn(st.sliderCard, st.mobileCard)}>
              <img src={item.image} alt={item.title} />
              <div className={st.flags}>
                {item.open24_hours === true && <span>Aberto 24h</span>}
                {item.restaurant === true && (
                  <span className={st.restaurant}>Restaurante</span>
                )}
              </div>
              <h3>{item.name}</h3>
              <p>{item.address}</p>
              <p>
                Telefone: <span>{item.phone}</span>
              </p>
            </div>
          </Link>
        ))}
    </section>
  )
}

export default Units
