import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import logo from '~/assets/svg/svg-logo-yellow.svg'
import cn from 'classnames'
import * as st from '~/assets/styl/Nav.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { animateScroll } from 'react-scroll'

type Props = {
  location: Location
}

const Nav = ({ location }: Props) => {
  const [transparent, setTransparent] = useState(true)

  useEffect(() => {
    if (location.pathname === '/') {
      const onScroll = () => {
        setTransparent(window.scrollY <= 203)
      }
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    }
  })

  const [navbarOpen, setNavbarOpen] = useState(false)
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
    document.body.classList.add('hidden')
  }
  const handleClose = () => {
    setNavbarOpen(false)
    document.body.classList.remove('hidden')
  }

  const scrollToTop = () => {
    animateScroll.scrollToTop()
  }

  const navItems = [
    { name: 'Sobre', href: '/#sobre' },
    { name: 'Unidades', href: '/#unidades' },
    // { name: 'Contato', href: '/#contatos' },
    { name: 'Fale Conosco', href: '/#fale-conosco' },
  ]

  return (
    <header
      className={cn(
        st.core,
        transparent && location.pathname === '/' && st.navTransparent,
        !transparent && st.animate
      )}
    >
      <div className={st.content1364}>
        <div
          className={cn(
            `${st.nav} ${navbarOpen ? `${st.noPadding}` : ''}`,
            transparent && location.pathname === '/' && st.transparent
          )}
        >
          {!transparent && (
            <button
              className={cn(
                !transparent && st.contentMobile,
                navbarOpen === true && st.opacity
              )}
              onClick={handleToggle}
            >
              <div></div>
              <div></div>
              <div></div>
            </button>
          )}
          {transparent && location.pathname != '/' && (
            <button
              className={cn(
                transparent && location.pathname != '/' && st.contentMobile,
                navbarOpen === true && st.opacity
              )}
              onClick={handleToggle}
            >
              <div></div>
              <div></div>
              <div></div>
            </button>
          )}
          <div
            className={`${st.logo} ${navbarOpen ? `${st.displayNone}` : ''}`}
          >
            {transparent && location.pathname === '/' ? (
              <img src={logo} alt="Logo da Bauer Postos" />
            ) : (
              <Link to="/" onClick={scrollToTop}>
                <img src={logo} alt="Logo da Bauer Postos" />
              </Link>
            )}
          </div>
          <nav
            className={cn(
              !transparent &&
                `${st.navMobile} ${navbarOpen ? `${st.showMenu}` : ''}`,
              transparent &&
                location.pathname != '/' &&
                `${st.navMobile} ${navbarOpen ? `${st.showMenu}` : ''}`
            )}
          >
            {navbarOpen && <button onClick={handleClose}>Fechar</button>}
            <ul>
              <li onClick={handleClose}>
                {!transparent && location.pathname === '/' && (
                  <Link to="/" onClick={scrollToTop}>
                    Home
                  </Link>
                )}
                {location.pathname !== '/' && <Link to="/">Home</Link>}
              </li>
              {navItems.map(({ name, href }, index) => (
                <li key={index} onClick={handleClose}>
                  <AnchorLink to={href}>{name}</AnchorLink>
                </li>
              ))}
              <li onClick={handleClose}>
                <Link
                  to="/#trabalhe-conosco"
                  onClick={() => (window as any).setTab?.(true)}
                >
                  Trabalhe Conosco
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Nav
