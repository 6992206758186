import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { useApi } from '~/api'
import { animateScroll } from 'react-scroll'
import * as st from '~/assets/styl/Footer.module.styl'
import logo from '~/assets/svg/svg-logo-footer.svg'

const Footer = () => {
  const socialsData = useApi(
    useStaticQuery(graphql`
      query {
        allConfigurations {
          nodes {
            facebook
            instagram
            linkedin
          }
        }
      }
    `).allConfigurations.nodes,
    'configurations'
  )

  const scrollToTop = () => {
    animateScroll.scrollToTop()
  }

  return (
    <footer className={st.core}>
      <div className={st.container1364}>
        <img src={logo} alt="" />
        <div className={st.containerPrincipal}>
          <ul className={st.firstUl}>
            <li>
              <Link to="/" onClick={scrollToTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/#sobre">Sobre</Link>
            </li>
            <li>
              <Link to="/#unidades">Unidades</Link>
            </li>
            <li>
              <Link to="/#fale-conosco">Fale Conosco</Link>
            </li>
            <li>
              <Link
                to="/#trabalhe-conosco"
                onClick={() => (window as any).setTab?.(true)}
              >
                Trabalhe Conosco
              </Link>
            </li>
            <li>
              <Link to="/contato-comercial">Contato Comercial</Link>
            </li>
          </ul>
          <ul>
            <li>Bauer Express © 2021. Todos os direitos reservados</li>
            <li>
              <a href="/politica-de-privacidade">
                <u>Política de privacidade</u>
              </a>
            </li>
            <li>
              <a
                href="https://platform.senior.com.br/login/?redirectTo=https%3A%2F%2Fplatform.senior.com.br%2Fsenior-x%2F&tenant=bauerexpress.com.br"
                target="_blank"
                rel="noreferrer"
              >
                <u>Portal do colaborador</u>
              </a>
            </li>
            <li>
              <a href="mailto:marketing@bauerpostos.com.br">
                marketing@bauerpostos.com.br
              </a>
            </li>
          </ul>
        </div>
        <ul className={st.socialMedias}>
          <li>
            <a
              href={socialsData.facebook}
              target="_blank"
              rel="noreferrer"
              className={st.facebook}
            ></a>
          </li>
          <li>
            <a
              href={socialsData.instagram}
              target="_blank"
              rel="noreferrer"
              className={st.instagram}
            ></a>
          </li>
          <li>
            <a
              href={socialsData.linkedin}
              target="_blank"
              rel="noreferrer"
              className={st.linkedin}
            ></a>
          </li>
        </ul>
      </div>
      <a
        href="https://www.tiki.com.br/"
        target="_blank"
        rel="noreferrer"
        className={st.tikiLogo}
      >
        Tiki
      </a>
    </footer>
  )
}

export default Footer
