import React, { useState, useRef, ChangeEvent, useEffect } from 'react'
import InputMask from 'react-input-mask'
import cn from 'classnames'
import { navigate } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import api from '~/api'
import { serialize } from 'object-to-formdata'
import * as st from '~/assets/styl/FormBusinessContact.module.styl'

type statesProps = {
  id: number
  nome: string
  sigla: string
}

type citiesProps = {
  id: number
  nome: string
}

const FormBusinessContact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    state: 'none',
    city: 'none',
    phone: '',
    reason: 'none',
    message: '',
  })
  const [terms, setTerms] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA>()
  const [states, setStates] = useState<statesProps[]>([])
  const [cities, setCities] = useState<citiesProps[]>([])
  const baseUrl = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/'
  const reasonContact = [
    {
      id: 1,
      nome: 'Proposta',
    },
    {
      id: 2,
      nome: 'Reunião',
    },
    {
      id: 3,
      nome: 'Visita',
    },
  ]

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const updateForm = { ...formData }
    const { name, value } = e.target
    updateForm[name] = value
    setFormData(updateForm)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    recaptchaRef.current.executeAsync().then((recaptcha) => {
      const body = { ...formData, privacy: terms, recaptcha }

      api('post', 'page-contact-business', serialize(body))
        .then(() => navigate('/sucesso'))
        .catch((error) => {
          console.log(error)
        })

      recaptchaRef.current?.reset()
    })
  }

  const requestApi = (url: string, useState) => {
    axios
      .get(url)
      .then((response) => useState(response.data))
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (formData.state !== 'none') {
      requestApi(baseUrl + `${formData.state}/municipios`, setCities)
    } else {
      requestApi(baseUrl, setStates)
    }
  }, [formData.state])

  return (
    <form
      onSubmit={handleSubmit}
      className={cn('displayFlex', st.form)}
      autoComplete="off"
    >
      <div className={st.inputContainer}>
        <div className={st.inputWrapper}>
          <label htmlFor="name" className="label">
            Nome*
          </label>
          <input
            type="text"
            id="name"
            name="name"
            required
            value={formData.name}
            onChange={handleChange}
            className="inputGenericDesign"
          />
        </div>
        <div className={st.inputWrapper}>
          <label htmlFor="email" className="label">
            E-mail*
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            className="inputGenericDesign"
          />
        </div>
        <div className={st.inputWrapper}>
          <label htmlFor="state" className="label">
            Estado*
          </label>
          <select
            name="state"
            id="state"
            required
            onChange={handleChange}
            value={formData.state}
            className="inputGenericDesign"
          >
            <option value="none" disabled defaultValue={'Selecione'}>
              Selecione
            </option>
            {states.map(({ id, nome, sigla }) => (
              <option value={sigla} key={id}>
                {nome}
              </option>
            ))}
          </select>
        </div>
        <div className={st.inputWrapper}>
          <label htmlFor="city" className="label">
            Cidade*
          </label>
          <select
            className="inputGenericDesign"
            name="city"
            id="city"
            required
            value={formData.city}
            onChange={handleChange}
          >
            <option value="none" disabled defaultValue={'Selecione'}>
              Selecione
            </option>
            {cities.map(({ id, nome }) => (
              <option value={nome} key={id}>
                {nome}
              </option>
            ))}
          </select>
        </div>
        <div className={st.inputWrapper}>
          <label htmlFor="phone" className="label">
            Telefone*
          </label>
          <InputMask
            className="inputGenericDesign"
            type="text"
            id="phone"
            name="phone"
            mask="(99) 99999-9999"
            required
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className={st.inputWrapper}>
          <label htmlFor="reason" className="label">
            Motivo do contato*
          </label>
          <select
            className="inputGenericDesign"
            name="reason"
            id="reason"
            required
            value={formData.reason}
            onChange={handleChange}
          >
            <option value="none" disabled defaultValue={'Selecione'}>
              Selecione
            </option>
            {reasonContact.map(({ id, nome }) => (
              <option value={nome} key={id}>
                {nome}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={cn('displayFlex', st.textareaContainer)}>
        <div className={st.textarea}>
          <label htmlFor="message" className="label">
            Mensagem
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="inputGenericDesign"
          ></textarea>
        </div>
        <div className={st.checkbox}>
          <input
            type="checkbox"
            value="terms"
            name="terms"
            id="terms"
            required
            checked={terms}
            onChange={({ target }) => setTerms(target.checked)}
          />
          <label htmlFor="terms">
            Declaro que li e aceito a{' '}
            <a href="/politica-de-privacidade">Política de privacidade</a>
          </label>
        </div>
      </div>
      <div className={st.recaptcha}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LcIUC8gAAAAADQxE_SyenZ6nIhwjKx6hK1Y5F5J"
          size="invisible"
        />
      </div>
      <button className={st.button}>Enviar</button>
    </form>
  )
}

export default FormBusinessContact
