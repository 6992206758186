import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useApi } from '~/api'
import * as st from '~/assets/styl/Timeline.module.styl'
import cn from 'classnames'

const Timeline = () => {
  const timelineYears = useApi(
    useStaticQuery(graphql`
      query {
        allTimelines {
          nodes {
            id
            image
            text
            title
          }
        }
      }
    `).allTimelines.nodes,
    'timelines'
  )

  const [year, setYear] = useState(timelineYears[0].title || null)
  const [image, setImage] = useState(timelineYears[0].image || null)
  const [text, setText] = useState(timelineYears[0].text || null)
  const [selected, setSelected] = useState(true)

  const getInfos = (year, image, text) => {
    setYear(year)
    setImage(image)
    setText(text)
    setInterval(() => setSelected(true), 500)
  }

  return (
    <section className={st.core}>
      <h2>Linha do tempo Bauer Postos</h2>
      <div>
        <div className={st.btnContainer}>
          {timelineYears.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                setSelected(false)
                getInfos(item.title, item.image, item.text)
              }}
              className={cn(item.text === text && st.active)}
            >
              {item.title}
            </button>
          ))}
        </div>
        <section className={selected && st.selected}>
          <h2>{year}</h2>
          <div className={cn(!image && st.backRed)}>
            {image && (
              <figure>
                <img
                  src={image}
                  alt="Uma imagem impressionante"
                  className={st.active}
                />
                <figcaption>{text}</figcaption>
              </figure>
            )}
            {!image && <p>{text}</p>}
          </div>
        </section>
      </div>
    </section>
  )
}

export default Timeline
