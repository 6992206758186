import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick'
import { ReactLightBox } from '~/components'
import cn from 'classnames'
// import { graphql, useStaticQuery } from 'gatsby'
// import { useApi } from '~/api'
import api from '~/api'
import * as st from '~/assets/styl/SlickHome.module.styl'

const SlickHome = () => {
  // const events = useApi(
  //   useStaticQuery(graphql`
  //     query {
  //       allEvents {
  //         nodes {
  //           image
  //           image_zoom
  //           title
  //           locale
  //           date_text
  //         }
  //       }
  //     }
  //   `).allEvents.nodes,
  //   'events'
  // )

  const slider = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [photos, setPhotos] = useState<Array<string>>()
  const [index, setIndex] = useState(0)
  const [events, setEvents] = useState([])

  useEffect(() => {
    api('get', 'events')
      .then((data) => data.result)
      .then((events) => {
        setEvents(events)
      })
      .catch((error) => {
        console.error('Erro ao buscar eventos:', error)
      })
  }, [])

  const handleSlideChange = (index) => {
    setCurrentSlide(index)
  }

  const getSlidesToShow = () => {
    if (events.length === 1) return 1
    if (events.length === 2) return 2
    if (events.length === 3) return 3
    if (events.length > 3) return 4
  }

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 1,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1699,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  }
  return (
    <>
      {events.length > 0 && (
        <section className={cn(st.content1364)}>
          <h2>Eventos</h2>
          <div className={st.containerSlider}>
            {events !== undefined && (
              <>
                <Slider {...settings} className="slick-events" ref={slider}>
                  {events.map((item, index) => (
                    <div key={index}>
                      <div className={st.cardWrapper}>
                        <div
                          className={st.cardImage}
                          onClick={() => {
                            setIndex(index)
                            setPhotos(events.map((i) => i.image_zoom || i.url))
                          }}
                        >
                          <img src={item.image} />
                          <h3>{item.title}</h3>
                          <p>Data: {item.date_text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                {events.length > 3 && (
                  <div className={cn(st.controls)}>
                    <button
                      onClick={() => {
                        slider?.current?.slickPrev()
                        handleSlideChange(currentSlide - 1)
                      }}
                    ></button>
                    <button
                      onClick={() => {
                        slider?.current?.slickNext()
                        handleSlideChange(currentSlide + 1)
                      }}
                    ></button>
                  </div>
                )}

                <ReactLightBox
                  index={index}
                  images={photos}
                  setIndex={setIndex}
                  setImages={setPhotos}
                />
              </>
            )}
          </div>
        </section>
      )}
    </>
  )
}

export default SlickHome
