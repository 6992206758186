import React, { useEffect, useState } from 'react'
import {
  GoogleMap as _GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import { graphql, useStaticQuery } from 'gatsby'
import { useApi } from '~/api'
import { ButtonRoutes } from '~/components'
import * as st from '~/assets/styl/Map.module.styl'
import pin from '~/assets/svg/svg-maps-icon.svg'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GoogleMap = _GoogleMap as any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const windowGlobal = typeof window !== 'undefined' ? (window as any) : {}

const MapWrapper = (props) => {
  const stationsData = useApi(
    useStaticQuery(graphql`
      query {
        allStations {
          nodes {
            id
            image
            slug
            title
            address
            hours
            latitude
            longitude
            open24_hours
            phone
            whatsapp
            name
          }
        }
      }
    `).allStations.nodes,
    'stations?max=9999'
  )

  const [active, setActive] = useState(false)
  const [name, setName] = useState(null)
  const [address, setAddress] = useState(null)
  const [phone, setPhone] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)

  const openModal = (name, address, phone, latitude, longitude) => {
    setName(name)
    setAddress(address)
    setPhone(phone)
    setLatitude(latitude)
    setLongitude(longitude)
    setActive(true)
  }

  const Map = withScriptjs(
    withGoogleMap(({ stations = [] }: { stations: Array<Station> }) => (
      <GoogleMap
        defaultZoom={9.1}
        defaultCenter={{ lat: -26.4894511, lng: -52.4952288 }}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        {active && (
          <section className={st.modal}>
            <button onClick={() => setActive(false)}>Fechar</button>
            <h2>{name}</h2>
            <p>{address}</p>
            <p>
              Telefone: <span>{phone}</span>
            </p>
            <ButtonRoutes href={`https://maps.google.com/?q=${address}`} />
          </section>
        )}
        {stationsData.map(
          ({ latitude: lat, longitude: lng, name, address, phone }, index) => (
            <Marker
              key={index}
              position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
              icon={{
                url: pin,
                anchor: new windowGlobal.google.maps.Point(22, 60),
                size: new windowGlobal.google.maps.Size(45, 60),
              }}
              onClick={() => openModal(name, address, phone, lat, lng)}
            />
          )
        )}
      </GoogleMap>
    ))
  )

  return (
    <>
      <Map
        googleMapURL={
          'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places'
        }
        loadingElement={<></>}
        containerElement={<div className={st.core}></div>}
        mapElement={<div></div>}
        {...props}
      />
    </>
  )
}

export default MapWrapper
