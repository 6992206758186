import React from 'react'
import * as st from '~/assets/styl/ButtonRoutes.module.styl'

const ButtonRoutes = ({ href }) => {
  return (
    <a
      className={st.core}
      title="Bauer Postos - traçar rota para Unidade Chapecó-SC"
      rel="noreferrer"
      target="_blank"
      href={href}
    >
      Traçar uma rota
    </a>
  )
}

export default ButtonRoutes
