// extracted by mini-css-extract-plugin
export var active = "Nav-module--active--icX2W";
export var animate = "Nav-module--animate--6igA-";
export var content1364 = "Nav-module--content-1364--+FItI";
export var contentMobile = "Nav-module--content-mobile--54j32";
export var core = "Nav-module--core--rWT0w";
export var displayNone = "Nav-module--display-none--LkQo-";
export var logo = "Nav-module--logo--HxTTt";
export var nav = "Nav-module--nav--Y5fPd";
export var navMobile = "Nav-module--nav-mobile--VzBGo";
export var navTransparent = "Nav-module--nav-transparent--C+6pS";
export var noPadding = "Nav-module--no-padding--+joMo";
export var opacity = "Nav-module--opacity--5pV8F";
export var showMenu = "Nav-module--show-menu--zoNCc";
export var slideDown = "Nav-module--slideDown--JOlFC";
export var transparent = "Nav-module--transparent--i-Wnt";